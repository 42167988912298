import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { InspirationItemProps } from './models';

import './InspirationItem.scss';

const InspirationItem: FC<InspirationItemProps> = ({
  data: { title, imageFirst, imageSecond },
  altImageItemBefore,
  altImageItemAfter,
  titleItemBefore,
  titleItemAfter,
}) => (
  <Col as="li" data-testid="inspiration-item" className="inspiration-item" md={6} sm={12} xl={6}>
    <div className="inspiration-item__card">
      <Row>
        <Col lg={12}>
          <DangerouslySetInnerHtml className="inspiration-item__title" html={title} />
        </Col>
      </Row>

      <div className="inspiration-item__subcard-list">
        <div className="inspiration-item__subcard">
          <div className="inspiration-item__subcard-image-wrapper">
            <Image
              imageData={imageFirst}
              alt={altImageItemBefore}
              className="inspiration-item__image"
              objectFit="cover"
            />
          </div>

          <DangerouslySetInnerHtml className="inspiration-item__subtitle" html={titleItemBefore} />
        </div>

        <div className="inspiration-item__subcard">
          <div className="inspiration-item__subcard-image-wrapper">
            <Image
              imageData={imageSecond}
              alt={altImageItemAfter}
              className="inspiration-item__image"
              objectFit="cover"
            />
          </div>

          <DangerouslySetInnerHtml className="inspiration-item__subtitle" html={titleItemAfter} />
        </div>
      </div>
    </div>
  </Col>
);

export const query = graphql`
  fragment InspirationItemFragment on InspirationItemType {
    title

    imageFirst {
      childImageSharp {
        gatsbyImageData(height: 300, placeholder: BLURRED, quality: 100)
      }
    }
    imageSecond {
      childImageSharp {
        gatsbyImageData(height: 300, placeholder: BLURRED, quality: 100)
      }
    }
  }
`;

export default InspirationItem;
