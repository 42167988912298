import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import Image from 'common/Image';
import Banner from 'components/Banner';
import BubblesBannerCarousel from 'components/vanishVictories/BubblesBannerCarousel';

import { BubblesBannerProps } from './models';

import './BubblesBanner.scss';

const BubblesBanner: FC<BubblesBannerProps> = ({
  data: { banner, bannerItems, imageFirst, imageSecond, imageThird, commonImagesAlt },
}) => (
  <Row data-testid="bubbles-banner" className="bubbles-banner">
    <Image imageData={imageFirst} alt={commonImagesAlt || ''} className="bubbles-banner__image" />
    <Image imageData={imageSecond} alt={commonImagesAlt || ''} className="bubbles-banner__image" />
    <Image imageData={imageThird} alt={commonImagesAlt || ''} className="bubbles-banner__image" />

    <Col>
      {banner?.length && !bannerItems?.length ? <Banner {...banner[0]} /> : null}
      {bannerItems?.length ? <BubblesBannerCarousel carouselItems={bannerItems} /> : null}
    </Col>
  </Row>
);

export const query = graphql`
  fragment BubblesBannerFragment on BubblesBannerType {
    banner {
      ...BannerFragment
    }
    bannerItems {
      ...BannerFragment
    }
    imageFirst {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageSecond {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageThird {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    commonImagesAlt
  }
`;

export default BubblesBanner;
