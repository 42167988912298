import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import Image from 'common/Image';
import Banner from 'components/Banner';

import { BubblesBannerResultsProps } from './models';

import './BubblesBannerResults.scss';

const BubblesBannerResults: FC<BubblesBannerResultsProps> = ({
  data: { banner, imageFirst, imageSecond, imageThird, commonImagesAlt },
}) => (
  <Row data-testid="bubbles-banner-results" className="bubbles-banner-results">
    <Image
      imageData={imageFirst}
      alt={commonImagesAlt || ''}
      className="bubbles-banner-results__image"
    />
    <Image
      imageData={imageSecond}
      alt={commonImagesAlt || ''}
      className="bubbles-banner-results__image"
    />
    <Image
      imageData={imageThird}
      alt={commonImagesAlt || ''}
      className="bubbles-banner-results__image"
    />
    <Image
      imageData={imageThird}
      alt={commonImagesAlt || ''}
      className="bubbles-banner-results__image"
    />
    <Image
      imageData={imageFirst}
      alt={commonImagesAlt || ''}
      className="bubbles-banner-results__image"
    />
    <Col>
      <Banner {...banner[0]} />
    </Col>
  </Row>
);

export const query = graphql`
  fragment BubblesBannerResultsFragment on BubblesBannerResultsType {
    banner {
      ...BannerFragment
    }
    imageFirst {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageSecond {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageThird {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageFourth {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    imageFifth {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
    }
    commonImagesAlt
  }
`;

export default BubblesBannerResults;
