import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import BubblesBanner from 'components/vanishVictories/BubblesBanner';
import BubblesBannerResults from 'components/vanishVictories/BubblesBannerResults';
import EnterStepList from 'components/vanishVictories/EnterStepList';
import InspirationList from 'components/vanishVictories/InspirationList';
import VictoriesBanner from 'components/vanishVictories/VictoriesBanner';
import VictoriesForm from 'components/vanishVictories/VictoriesForm';

import { VanishVictoriesPageProps } from './models';

import './VanishVictoriesPage.scss';

const VanishVictoriesPage: FC<VanishVictoriesPageProps> = ({
  data: {
    vanishVictoriesPage: {
      seo,
      langProps,
      banner,
      enterStepsList,
      victoriesForm,
      bubblesBanner,
      bubblesBannerResults,
      inspirationList,
      hideBubblesBanner,
      hideBubblesBannerResults,
      footerType,
    },
  },
  pageContext: { pagePathname },
}) => {
  const schemaImageUrl =
    banner?.[0]?.backgroundImage?.imagePicker?.childImageSharp?.gatsbyImageData?.images?.fallback
      ?.src;

  return (
    <Layout
      className="vanish-victories-page"
      {...{
        seo,
        seoOGType: OG_PAGE_TYPES.WEBSITE,
        langProps,
        pagePathname,
        additionalSchema: 'WebPage',
        schemaImageUrl,
      }}
      footerType={footerType}
    >
      <VictoriesBanner data={banner?.[0]} />
      <EnterStepList data={enterStepsList?.[0]} />
      <VictoriesForm data={victoriesForm?.[0]} />
      {bubblesBanner?.length && !hideBubblesBanner ? (
        <BubblesBanner data={bubblesBanner?.[0]} />
      ) : null}
      {bubblesBannerResults?.length && !hideBubblesBannerResults ? (
        <BubblesBannerResults data={bubblesBannerResults?.[0]} />
      ) : null}

      {inspirationList?.[0] ? <InspirationList data={inspirationList[0]} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    vanishVictoriesPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      pageName
      footerType {
        type
      }
      banner {
        ...VictoriesBannerFragment
      }
      enterStepsList {
        ...EnterStepListFragment
      }
      victoriesForm {
        ...VictoriesFormFragment
      }
      bubblesBanner {
        ...BubblesBannerFragment
      }
      bubblesBannerResults {
        ...BubblesBannerResultsFragment
      }
      inspirationList {
        ...InspirationListFragment
      }
      hideBubblesBanner
      hideBubblesBannerResults
    }
  }
`;

export default VanishVictoriesPage;
