import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import InspirationItem from '../InspirationItem';
import { InspirationListProps } from './models';

import './InspirationList.scss';

const InspirationList: FC<InspirationListProps> = ({
  data: {
    title,
    items,
    text,
    titleItemAfter,
    titleItemBefore,
    altImageItemAfter,
    altImageItemBefore,
  },
}) => (
  <Row data-testid="inspiration-list" className="inspiration-list">
    <Col>
      <DangerouslySetInnerHtml html={title} className="inspiration-list__title" />
      <DangerouslySetInnerHtml html={text} className="inspiration-list__text" />

      <Row as="ul" className="inspiration-list__cards">
        {items?.map((item) => (
          <InspirationItem
            key={item.title}
            data={item}
            titleItemAfter={titleItemAfter}
            titleItemBefore={titleItemBefore}
            altImageItemAfter={altImageItemAfter}
            altImageItemBefore={altImageItemBefore}
          />
        ))}
      </Row>
    </Col>
  </Row>
);

export const query = graphql`
  fragment InspirationListFragment on InspirationListType {
    title
    text
    titleItemAfter
    titleItemBefore
    altImageItemAfter
    altImageItemBefore
    items {
      ...InspirationItemFragment
    }
  }
`;

export default InspirationList;
