import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';

import EnterStepItem from '../EnterStepItem';
import { EnterStepListProps } from './models';

import './EnterStepList.scss';

const EnterStepList: FC<EnterStepListProps> = ({ data: { title, items, text } }) => (
  <div data-testid="enter-step-list" className="enter-step-list">
    <Row>
      <Col>
        <DangerouslySetInnerHtml html={title} className="enter-step-list__title" />
        <DangerouslySetInnerHtml html={text} className="enter-step-list__text" />
      </Col>
    </Row>

    <Row>
      <Col>
        <ul className="enter-step-list__cards">
          {items?.map((item) => (
            <EnterStepItem key={item.title} data={item} />
          ))}
        </ul>
      </Col>
    </Row>
  </div>
);

export const query = graphql`
  fragment EnterStepListFragment on EnterStepListType {
    title
    text
    items {
      ...EnterStepItemFragment
    }
  }
`;

export default EnterStepList;
