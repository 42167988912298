import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';
import PromoFormComponent from '@promo-platform/promo-form';

import { DangerouslySetInnerHtml } from 'layout';

import { VictoriesFormProps } from './models';

import './VictoriesForm.scss';

import '@promo-platform/styles';

const VictoriesForm: FC<VictoriesFormProps> = ({ data: { title, form } }) => (
  <Row data-testid="victories-form" lg={12} className="victories-form">
    <DangerouslySetInnerHtml className="victories-form__title" html={title} />
    <Col>
      {form?.[0]?.id && form?.[0]?.url ? (
        <div className="victories-form__form-wrapper">
          <PromoFormComponent id={`${form[0].id}`} url={form[0].url} />
        </div>
      ) : null}
    </Col>
  </Row>
);

export const query = graphql`
  fragment VictoriesFormFragment on VictoriesFormType {
    title
    form {
      ...BasePromoFormTypeFragment
    }
  }
`;

export default VictoriesForm;
