import React, { FC, useRef } from 'react';
import { useId } from 'react-id-generator';
import Carousel from 'react-multi-carousel';

import Banner from 'components/Banner';

import { RESPONSIVE } from './constants';
import { IBubblesBannerCarouselProps } from './models';

import 'react-multi-carousel/lib/styles.css';
import './BubblesBannerCarousel.scss';

const BubblesBannerCarousel: FC<IBubblesBannerCarouselProps> = ({ carouselItems }) => {
  const carousel = useRef<Carousel>(null);
  const keys = useId(carouselItems?.length);

  return (
    <Carousel
      containerClass="bubbles-banner-carousel"
      ref={carousel}
      responsive={RESPONSIVE}
      infinite
      autoPlay
      autoPlaySpeed={5000}
      arrows={false}
      showDots
    >
      {carouselItems?.map((item, index) => (
        <Banner key={keys[index]} {...item} />
      ))}
    </Carousel>
  );
};

export default BubblesBannerCarousel;
