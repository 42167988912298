import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import SvgImageIcon from 'common/SvgImageIcon';

import { EnterStepItemProps } from './models';

import './EnterStepItem.scss';

const EnterStepItem: FC<EnterStepItemProps> = ({ data: { image, imageAlt, text, title } }) => (
  <Col as="li" data-testid="enter-step-item" className="enter-step-item">
    <div className="enter-step-item__card">
      <div className="enter-step-item__icon-wrapper">
        <SvgImageIcon customClassName="enter-step-item__icon" icon={image} iconAlt={imageAlt} />
      </div>
      <div>
        <DangerouslySetInnerHtml className="enter-step-item__title" html={title} />
        <DangerouslySetInnerHtml className="enter-step-item__text" html={text} />
      </div>
    </div>
  </Col>
);

export const query = graphql`
  fragment EnterStepItemFragment on EnterStepItemType {
    title
    text
    image {
      fallbackUrl
      url
    }
    imageAlt
  }
`;

export default EnterStepItem;
